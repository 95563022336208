import React, { createContext, useState, useContext, useEffect } from "react";

import useRequest from "../hooks/useRequest";

export const AppStateContext = createContext();

export const AppContextProvider = ({ children }) => {
  const { request } = useRequest();

  const [pageHeading, setPageHeading] = useState("Heading");
  const [language, setLanguage] = useState("en");

  const [loading, setLoading] = useState(true);

  const [country, setCountry] = useState({
    country_id: undefined,
    currency: undefined,
  });

  useEffect(() => {
    if (language != "en") {
      console.log("Import Css");
      import("../assets/styles/rtl.css");
    } else {
      console.log(" remove Import Css");
    }
  }, [country.country_id]);

  useEffect(() => {
    setLoading(true);
    let lang = localStorage.getItem("languageSet");
    lang = lang ? lang : "en";

    if (lang == "ar") {
      console.log("Import Css");
      import("../assets/styles/rtl.css");
    } else {
      import("../assets/styles/main.css");
    }

    setTimeout(() => setLoading(false), 200);
    setLanguage(lang);
  }, []);

  return (
    <AppStateContext.Provider
      value={{
        pageHeading,
        setPageHeading,
        setCountry,
        country,
        language,
        setLanguage,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppStateContext);
};
