import { UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Select,
  Table,
  Tooltip,
  Form,
  Input,
  Modal,
  Radio,
  TimePicker,
  DatePicker,
  InputNumber,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import Currency from "../../components/Currency";
import apiPath from "../../constants/apiPath";
import { Months } from "../../constants/var";
import { AppStateContext } from "../../context/AppContext";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import moment from "moment";
import { useAuthContext } from "../../context/AuthContext";
import { useForm } from "antd/lib/form/Form";
import SingleImageUpload from "../../components/SingleImageUpload";
import { InvoicePaymentStatus } from "../../constants/invoice-status-constants";

function Pending() {
  const { setPageHeading, country } = useContext(AppStateContext);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();

  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [paymentModal, showPaymentModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [restaurants, setRestaurant] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    restaurant_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
    status: status ?? undefined,
    payment_mod: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${apiPath.collection}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years, restaurants } = res;
        setCities(data);
        setYears(years);
        setRestaurant(restaurants);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const handleChange = (pagination, sorter, filters) => {
    if (!sorter) {
      fetchData(pagination);
    }
  };

  const fetchData = (pagination, status) => {
    setLoading(true);

    const payload = { ...filter };
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize = pagination ? pagination?.pageSize : 10;

    const queryString = Object.entries(payload)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        `${apiPath.collection}/payment/pending` +
        `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, total, status }) => {
        setLoading(false);
        if (status) {
          setList(data);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [refresh, filter]);

  const columns = [
    {
      title: lang("Rest. ID"),
      dataIndex: "id",
      render: (_, { restaurant_id }) =>
        `#${restaurant_id ? restaurant_id.uid : ""}`,
    },
    {
      title: lang("NAME"),
      dataIndex: "name",
      key: "name",
      render: (_, { restaurant_id }) => `${restaurant_id.name}`,
    },
    {
      title: lang("payment Date & time"),
      dataIndex: "dateTime",
      key: "dateTime",
      render: (_, { created_at }) => `${dateString(created_at, "ll")}`,
    },
    {
      title: lang("Country"),
      dataIndex: "country",
      key: "country",
      render: (_, { country_id }) => `${country_id.name}`,
    },
    {
      title: lang("City"),
      dataIndex: "city",
      key: "city",
      render: (_, { city_id }) => `${city_id.name}`,
    },
    {
      title: lang("Area"),
      dataIndex: "payment_Method",
      key: "payment_Method",
      render: (_, { restaurant_id }) =>
        `${restaurant_id.area ? restaurant_id.area.name : ""}`,
    },
    {
      title: lang("Total Amount"),
      dataIndex: "delivery_status",
      key: "delivery_status",
      render: (_, { amount }) => <Currency price={amount ? amount : 0} />,
    },
    {
      title: lang("Payment Mode"),
      dataIndex: "Item_Description",
      key: "Item_Description",
      render: (_, { payment_mod }) => (payment_mod ? payment_mod : 0),
    },
    // {
    //   title: "Pending Amount",
    //   dataIndex: "pendingAmount",
    //   key: "pendingAmount",
    // },
    {
      title: lang("Payment receipt"),
      dataIndex: "Item_Description",
      key: "Item_Description",
      render: (_, { image, name }) => (
        <Image src={image ? image : null} className="table-img image-doc" />
      ),
    },
    // {
    //   title: "receipt No.",
    //   dataIndex: "Item_Description",
    //   key: "Item_Description",
    // },
    {
      title: lang("status"),
      render: (_, record) => {
        let status = record.status;

        let bgcolor = "#F3E008";
        let color;
        if (status === InvoicePaymentStatus.APPROVED) {
          if (record.driver_id) {
            status = "Ready to Pick Up";
            bgcolor = "#28DA28";
            color = "#414454";
          }
        } else if (status === InvoicePaymentStatus.REJECTED) {
          bgcolor = "#ED1313";
          color = "#414454";
        } else if (status === InvoicePaymentStatus.PENDING) {
          bgcolor = "#2AA0F5";
          color = "#fff";
        }
        return (
          <>
            <Tooltip
              title={lang("Approval Pending")}
              color={"purple"}
              key={"viewDetail"}
            >
              <Button
                style={{ backgroundColor: bgcolor }}
                className="btnStyle btnOutlineDelete"
                onClick={() => {
                  if (record.status === "Rejected") {
                    setSelectedInvoice(record);
                    showPaymentModal(true);
                  }
                }}
              >
                {lang(`${record.status}`)}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setPageHeading(lang("Collection Management"));
  }, []);

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <>
                <div className="tab_inner_tit">
                  <div className="tab-upload-wrap d-flex align-items-center justify-content-between">
                    <h3>{lang(`Pending Approval`)}</h3>
                    <div className="tab-detail-inputs">
                      {/* <div className="city-wrap">
                        <Select
                          width="110"
                          value={filter.country_id}
                          placeholder="Country"
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            getCities(value);
                            onChange("country_id", value);
                          }}
                          options={countries.map((item) => ({
                            value: item._id,
                            label: item.name,
                          }))}
                        />
                      </div> */}
                      <div className="role-wrap">
                        <Select
                          width="110px"
                          placeholder={lang("City")}
                          showSearch
                          value={filter.city_id}
                          //filterOption={false}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={cities.map((item) => ({
                            value: item._id,
                            label: item.name,
                          }))}
                          // onPopupScroll={handleScroll}
                          // onSearch={(newValue) => setSearchCity(newValue)}
                          onChange={(value) => onChange("city_id", value)}
                        />
                      </div>
                      <div className="role-wrap">
                        <Select
                          width="110px"
                          placeholder={lang("Year")}
                          showSearch
                          value={filter.year}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={years.map((item) => ({
                            value: item,
                            label: item,
                          }))}
                          onChange={(value) => onChange("year", value)}
                        />
                      </div>
                      <div className="role-wrap">
                        <Select
                          width="110px"
                          placeholder={lang("Month")}
                          showSearch
                          value={filter.month}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={months.map(({ value, label }) => ({
                            value,
                            label,
                          }))}
                          onChange={(value) => onChange("month", value)}
                        />
                      </div>
                      <div className="city-wrap">
                        <Select
                          width="250"
                          style={{ minWidth: "150px" }}
                          placeholder={lang("Payment Mode")}
                          value={filter.payment_mod}
                          options={[
                            { value: "Wire", label: "Wire" },
                            { value: "Cash", label: "Cash On Delivery" },
                          ]}
                          onChange={(value) => onChange("payment_mod", value)}
                        />
                      </div>
                      <div className="city-wrap">
                        <Select
                          width="250"
                          style={{ minWidth: "150px" }}
                          placeholder={lang("Status")}
                          value={filter.status}
                          options={[
                            { value: "Pending", label: lang("Pending") },
                            { value: "Approved", label: lang("Approved") },
                            { value: "Rejected", label: lang("Rejected") },
                          ]}
                          onChange={(value) => onChange("status", value)}
                        />
                      </div>
                      <div className="city-wrap">
                        <Select
                          style={{ minWidth: "150px" }}
                          placeholder={lang("Restaurant")}
                          showSearch
                          value={filter.restaurant_id}
                          // filterOption={false}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={restaurants.map((item) => ({
                            value: item._id,
                            label: item.name,
                          }))}
                          onChange={(value) => onChange("restaurant_id", value)}
                        />
                      </div>

                      <Button
                        onClick={() =>
                          setFilter({
                            country_id: undefined,
                            city_id: undefined,
                            year: undefined,
                            month: undefined,
                          })
                        }
                        type="primary"
                        icon={<UndoOutlined />}
                      >
                        {lang(`Reset`)}
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4> */}

                <div className="table-responsive customPagination withOutSearilNo">
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                      defaultPageSize: 10,
                      responsive: true,
                      total: pagination.total,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30", "50"],
                    }}
                    onChange={handleChange}
                    className="ant-border-space"
                  />
                </div>
              </>
            </Card>
          </Col>
        </Row>
      </div>
      {paymentModal && (
        <Payment
          data={selectedInvoice}
          show={paymentModal}
          hide={() => {
            showPaymentModal(false);
          }}
          refresh={() => setRefresh(!refresh)}
        />
      )}
    </>
  );
}

export const Payment = ({ show, hide, data, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [form] = useForm();
  const { request } = useRequest();

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  useEffect(() => {
    if (!data) return;
    console.log("data--", data);
    form.setFieldsValue({
      ...data,
      pay_date: moment(data?.pay_date),
      pay_time: moment(data?.pay_time),
    });
    setImage(data.image);
  }, [data]);

  const { currency } = useAuthContext();

  const onSubmit = (value) => {
    setLoading(true);
    const { amount, pay_date, pay_time, payment_type } = value;

    const payload = {
      amount,
      pay_date,
      pay_time,
      payment_type,
      image,
      invoice_id: data._id,
      restaurant_id: data.restaurant_id._id,
    };

    request({
      url: `${apiPath.collection}/update/${data._id}`,
      method: "PUT",
      data: payload,
      onSuccess: ({ status, message }) => {
        setLoading(false);
        if (status) {
          hide();
          refresh();
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleImage = (data) => {
    setImage(data.length ? data[0].url : null);
  };

  return (
    <Modal
      width={750}
      open={show}
      onOk={() => null}
      onCancel={hide}
      // title={`${data ? "Update " + section : "Create a New " + section}`}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        layout="vertical"
        form={form}
        initialValues={{
          is_active: true,
          name: data.restaurant_id.name,
          city: data.city_id.name,
          area: data.restaurant_id.area?.name ?? "",
        }}
        onFinish={onSubmit}
      >
        <h4 className="modal_title_cls">{lang("Receive Payment")}</h4>
        <span>
          {lang("Reason")} : {data?.reason}
        </span>

        <Row gutter={[16, 0]}>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Restaurant Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: lang("Name is required"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input
                disabled={true}
                autoComplete="off"
                placeholder={lang("Urla Restaurant")}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item label={lang("City")} name="city">
              <Input
                disabled
                autoComplete="off"
                placeholder={lang("Abu Dhabi")}
              />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item label={lang("Area")} name="area">
              <Input
                disabled
                autoComplete="off"
                placeholder={lang("Downtown")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Date")}
              name="pay_date"
              rules={[
                {
                  required: true,
                  message: lang("Date is required"),
                },
              ]}
            >
              <DatePicker
                autoComplete="off"
                placeholder={lang("Select Date")}
              />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Time")}
              name="pay_time"
              rules={[
                {
                  required: true,
                  message: lang("Time is required"),
                },
              ]}
            >
              <TimePicker
                use12Hours
                format="h:mm a"
                autoComplete="off"
                placeholder={lang("Select Time")}
              />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang(`${lang("Total Amount")} (${currency})`)}
              name="amount"
              // rules={[
              //   {
              //     required: true,
              //     message: lang("Amount is required"),
              //   },
              //   {
              //     validator: (_, value) => {
              //       if (value <= data.remaining_amount) {
              //         return Promise.resolve();
              //       }
              //       return Promise.reject(
              //         new Error(
              //           "Amount is not greater then invoice remaining amount",
              //         ),
              //       );
              //     },
              //   },
              // ]}
            >
              <InputNumber
                disabled={true}
                autoComplete="off"
                placeholder={"2,000"}
                onChange={(value) => {
                  if (value === data.remaining_amount) {
                    form.setFieldsValue({ payment_type: "Full payment" });
                  } else {
                    console.log("e.target.value", value);
                    form.setFieldsValue({ payment_type: "Partial payment" });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              className="upload_wrap"
              label={lang("Upload Image")}
              name="image"
              rules={[
                {
                  validator: (_, value) => {
                    if (image) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(lang("Image is required")));
                  },
                },
              ]}
            >
              <SingleImageUpload
                value={image}
                imageType={"invoice"}
                onChange={(data) => handleImage(data)}
                fileType={FileType}
              />
            </Form.Item>
            <div>
              <Image
                src={image ? image : null}
                className="table-img image-doc"
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={24}>
            <div className="status_wrap">
              <Form.Item
                label={lang("Choose a Payment")}
                name="payment_type"
                className="payment-form"
                rules={[
                  {
                    required: true,
                    message: lang("Payment Type is required"),
                  },
                ]}
              >
                <Radio.Group
                  disabled={true}
                  onChange={(e) => {
                    if (e.target.value === "Full payment") {
                      console.log("e.target.value", e.target.value);
                      form.setFieldsValue({ amount: data.remaining_amount });
                    } else {
                      console.log("e.target.value", e.target.value);
                      form.setFieldsValue({ amount: null });
                    }
                  }}
                >
                  <Radio value={"Full payment"}>{lang("Full payment")}</Radio>
                  <Radio value={"Partial payment"}>
                    {lang("Partial payment")}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Pending;
