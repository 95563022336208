import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import { UndoOutlined } from "@ant-design/icons";
import { Last20Years, Months } from "../../constants/var";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";

const fakeData = [
  {
    id: 101,
    name: "John's Restaurant",
    dateTime: "2023-12-08 10:30 AM",
    country: "USA",
    city: "New York",
    payment_Method: "Card",
    delivery_status: "$500",
    Item_Description: "Online",
    pendingAmount: "$100",
    paymentReceipt: "ABC123",
    status: "Approval Pending",
  },
  {
    id: 102,
    name: "Alice's Bistro",
    dateTime: "2023-12-07 02:45 PM",
    country: "Canada",
    city: "Toronto",
    payment_Method: "Cash",
    delivery_status: "$300",
    Item_Description: "Cash On Delivery",
    pendingAmount: "$50",
    paymentReceipt: "XYZ789",
    status: "Approval Pending",
  },
  {
    id: 103,
    name: "Mike's Diner",
    dateTime: "2023-12-06 08:15 AM",
    country: "UK",
    city: "London",
    payment_Method: "Online",
    delivery_status: "$700",
    Item_Description: "Credit Card",
    pendingAmount: "$200",
    paymentReceipt: "PQR456",
    status: "Approval Pending",
  },
  {
    id: 104,
    name: "Sara's Cafe",
    dateTime: "2023-12-05 04:30 PM",
    country: "Australia",
    city: "Sydney",
    payment_Method: "Card",
    delivery_status: "$400",
    Item_Description: "PayPal",
    pendingAmount: "$75",
    paymentReceipt: "MNO789",
    status: "Approval Pending",
  },
  {
    id: 105,
    name: "Bob's Grill",
    dateTime: "2023-12-04 12:00 PM",
    country: "Germany",
    city: "Berlin",
    payment_Method: "Online",
    delivery_status: "$600",
    Item_Description: "Net Banking",
    pendingAmount: "$120",
    paymentReceipt: "JKL123",
    status: "Approval Pending",
  },
];

function Pending() {
  const { setPageHeading, country } = useContext(AppStateContext);

  const [list, setList] = useState(fakeData);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();
  const api = {
    status: apiPath.statusQuote,
    list: apiPath.history,
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id, search, page) => {
    if (!id) return;
    request({
      url: `/country-city/${id}?page=${1}&pageSize=3000${
        search ? `&search=${search}` : ""
      }`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities((prev) => [...data]);
          //search ? setCities(prev => ([...data])) : setCities(prev => ([...prev, ...data]));
        }
      },
    });
  };

  useEffect(() => {
    if (!country.country_id) return;
    getCities(country.country_id);
  }, [country.country_id]);

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // useEffect(() => {
  //   if (!filter?.country_id) return
  //   getCities(filter.country_id, debouncedSearchCity, page);
  // }, [page]);

  // useEffect(() => {
  //   if (!filter?.country_id) return
  //   getCities(filter.country_id, debouncedSearchCity, 1);
  // }, [debouncedSearchCity]);

  const handleChange = (pagination, sorter, filters) => {
    if (!sorter) {
      fetchData(pagination);
    }
  };

  const fetchData = (pagination, status) => {
    setLoading(true);

    const payload = {};
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize = pagination ? pagination?.pageSize : 10;

    const queryString = Object.entries(payload)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url: api.list + `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, total, status }) => {
        setLoading(false);
        if (status) {
          setList(data);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    //  setLoading(true);
    //fetchData(pagination);
  }, [refresh, country.country_id]);

  const columns = [
    {
      title: "Rest. ID",
      dataIndex: "id",
      render: (_, { id }) => `#${id}`,
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "payment Date & time",
      dataIndex: "dateTime",
      key: "dateTime",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Area",
      dataIndex: "payment_Method",
      key: "payment_Method",
    },
    {
      title: "Total Amount",
      dataIndex: "delivery_status",
      key: "delivery_status",
    },
    {
      title: "Payment Mode",
      dataIndex: "Item_Description",
      key: "Item_Description",
    },
    {
      title: "Pending Amount",
      dataIndex: "pendingAmount",
      key: "pendingAmount",
    },
    {
      title: "Payment receipt",
      dataIndex: "Item_Description",
      key: "Item_Description",
    },
    // {
    //   title: "receipt No.",
    //   dataIndex: "Item_Description",
    //   key: "Item_Description",
    // },
    {
      title: "status",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={lang("Approved")}
              color={"purple"}
              key={"viewDetail"}
            >
              <Button
                className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                // onClick={(e) => view(record._id)}
              >
                {lang(`Approved`)}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setPageHeading(lang("Collection Management"));
  }, []);

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <>
                <div className="tab_inner_tit">
                  <div className="tab-upload-wrap d-flex align-items-center justify-content-between">
                    <h3>{lang(`Payment History`)}</h3>
                    <div className="tab-detail-inputs">
                      {/* <div className="city-wrap">
                        <Select
                          width="110"
                          value={filter.country_id}
                          placeholder="Country"
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            getCities(value);
                            onChange("country_id", value);
                          }}
                          options={countries.map((item) => ({
                            value: item._id,
                            label: item.name,
                          }))}
                        />
                      </div> */}
                      <div className="role-wrap">
                        <Select
                          last20Years
                          width="110px"
                          placeholder="City"
                          showSearch
                          value={filter.city_id}
                          //filterOption={false}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={cities.map((item) => ({
                            value: item._id,
                            label: item.name,
                          }))}
                          //onPopupScroll={handleScroll}
                          //onSearch={(newValue) => setSearchCity(newValue)}
                          onChange={(value) => onChange("city_id", value)}
                        />
                      </div>
                      <div className="role-wrap">
                        <Select
                          width="110px"
                          placeholder="Year"
                          showSearch
                          value={filter.year}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={Last20Years.map((item) => ({
                            value: item,
                            label: item,
                          }))}
                          onChange={(value) => onChange("year", value)}
                        />
                      </div>
                      <div className="role-wrap">
                        <Select
                          width="110px"
                          placeholder="Month"
                          showSearch
                          value={filter.month}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={Months.map(({ value, label }) => ({
                            value,
                            label,
                          }))}
                          onChange={(value) => onChange("month", value)}
                        />
                      </div>

                      <div className="city-wrap">
                        <Select
                          width="250"
                          style={{ minWidth: "150px" }}
                          placeholder="Payment Mode"
                          options={[
                            { value: "Online", label: "Online" },
                            { value: "cod", label: "Cash On Delivery" },
                          ]}
                        />
                      </div>
                      <div className="city-wrap">
                        <Select
                          width="250"
                          style={{ minWidth: "150px" }}
                          defaultValue="Restaurant"
                          options={[
                            { value: "Restaurant 1", label: "Restaurant 1" },
                            { value: "Restaurant 2", label: "Restaurant 2" },
                          ]}
                        />
                      </div>

                      <Button
                        onClick={() =>
                          setFilter({
                            country_id: undefined,
                            city_id: undefined,
                            year: undefined,
                            month: undefined,
                          })
                        }
                        type="primary"
                        icon={<UndoOutlined />}
                      >
                        {lang(`Reset`)}
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4> */}

                <div className="table-responsive customPagination withOutSearilNo">
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                      defaultPageSize: 10,
                      responsive: true,
                      total: pagination.total,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "30", "50"],
                    }}
                    onChange={handleChange}
                    className="ant-border-space"
                  />
                </div>
              </>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Pending;
