import en from "../locales/en.js";
import ar from "../locales/ar.js";

export const lang = (value) => {
  const userLang = localStorage.getItem("languageSet");

  // console.log(userLang ,value);

  const lang = userLang ? userLang : "en";
  switch (lang) {
    case "en":
      return en[value] ?? value;
    case "ar":
      return ar[value] ?? value;
    default:
      return value;
  }
};

export default lang;
