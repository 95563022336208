import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const dummy = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June'],
  datasets: [
    {
      label: 'Dataset 1',
      data: [20000, 8000, 5000, 16000],
      borderColor: '#F3E008',
      tension: 0.1,
    },
    {
      label: 'Dataset 2',
      data: [12000, 14000, 7000, 8000],
      borderColor: '#383B42',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
     // yAxisID: 'y1',
    },
  ],
};
const LineChart = ({data }) => {

  

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: false, // Hide the dataset label
      },
    },
  };

  return (
    <div>
      <Line
        data={data ?? dummy}
        options={options}
      />
    </div>
  )
}

export default LineChart