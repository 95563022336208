import { Card, Col, Row, Tabs } from "antd";
import React, { useState } from "react";

import { NotificationList } from "./_NotificationList";


function Notification() {

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <div
                className="notification-main-wrap"
                style={{ padding: "16px" }}
              >
                <NotificationList />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Notification;
