import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
        },
    },
};

const dummy = {
    labels: ['1st Week', '2nd Week', '3rd Week', '4th Week'],
    datasets: [
        {
            label: 'Full Payment : AED 15,500',
            data: [20000, 8000, 5000, 16000],
            backgroundColor: '#4990F2',
        },
        {
            label: 'Partial Payment : AED 2,000',
            data: [12000, 14000, 7000, 8000],
            backgroundColor: '#F3E008',
        },
    ],
};

const BarChart = ({ data }) => {
    return <Bar options={options} data={data ?? dummy} />;
}

export default BarChart
