const ar = {
  Dashboard: "اللوحة الرئيسية",
  "Collection List": "قائمة التحصيل",
  "Pending Approvals": "بانتظار الموافقة",
  "Payment History": "سجل الدفعات",
  Logout: "الخروج",
  "Are you sure you want to Logout the Application?":
    "متأكد من الخروج",
  "Edit Profile": "تعديل الملف الشخصي",
  "Change Password": "تغيير كلمة المرور",
  "Please enter the name!": "يرجى ادخال الاسم",
  "Only Alphabetic Characters Allowed": "الحروف الأبجدية مسموحة فقط",
  "The input is not valid E-mail!": "البريد الإلكتروني غير صالح!",
  "Enter Email Address": "ادخل البريد الالكتروني",
  "Please enter the email!": "الرجاء ادخال البريد الالكتروني",
  "Mobile Number": "رقم الهاتف",
  "Upload Profile": "تحميل الملف الشخصي",
  "App Store URL": "App Store URL",
  "Android Details": "Android Details",
  "Enter App Store URL": "Enter App Store URL",
  "Collection Management": "ادارة التحصيل",
  "This Month Collection": "تحصيل الشهر الحالي",
  "Full Payment": "دفعة كاملة",
  Payments: "الدفعات",
  Month: "الشهر",
  "Approval Pending": "بانتظار الموافقة",
  "Collection Management": "ادارة التحصيل",
  "Pending Approval": "بانتظار الموافقة",
  "VIEW ALL": "عرض الكل",
  Approved: "موافقة",
  "Payment History": "سجل الدفعات",
  "Collection List": "قائمة التحصيل",
  "Receive Payment": "استلام الدفعة",
  Invoice: "الفاتورة",
  "Are you sure you want to Logout the Application?":
    "متأكد من الخروج؟",
  Logout: "الخروج",
  "Please enter the name!": "يرجى ادخال الاسم",
  "Only Alphabetic Characters Allowed": "الحروف الأبجدية مسموحة فقط",
  "Enter Name": "ادخل الاسم",
  Upload: "التحميل",
  "Please enter the app store URL!": "Please enter the app store URL!",
  "Please enter the version!": "Please enter the version!",
  "Enter Android Version": "Enter Android Version",
  "Please enter the share content!": "Please enter the share content!",
  "Share Content": "Share Content",
  "Share Android Content": "Share Android Content",
  "Play Store URL": "Play Store URL",
  "Please enter the play store URL!": "Please enter the play store URL!",
  "Enter Play Store URL": "Enter Play Store URL",
  Version: "النسخة",
  "Please enter the version!": "Please enter the version!",
  "Enter IOS Version": "Enter IOS Version",
  "Share Content": "Share Content",
  "Please enter the share content!": "Please enter the share content!",
  "Share IOS Content": "Share IOS Content",
  "Change Password": "تغيير كلمة المرور",
  "Please enter the old password!": "ادخل كلمة المرور القديمة",
  "New Password": "كلمة المرور الجديدة",
  "Please enter the new password!": "يرجى ادخال كلمة المرور الجديدة",
  "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "كلمة المرور الجديدة تحتوي على الأقل على 8 أحرف، وعلى الأقل تحتوي على حرف كبير واحد، وعلى الأقل تحتوي على حرف واحد صغير، وعلى الأقل تحتوي على رقم واحد، وعلى الأقل تحتوي على حرف خاص واحد",
  "Old password & new password must be different!":
    "يجب أن تكون كلمة المرور القديمة وكلمة المرور الجديدة مختلفتين!",
  "Confirm New Password": "تأكيد كلمة المرور الجديدة",
  "Please enter the confirm password!": "الرجاء إدخال تأكيد كلمة المرور!",
  "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "تأكيد كلمة المرور تحتوي على الأقل على 8 أحرف، وعلى الأقل تحتوي على حرف كبير واحد، وعلى الأقل تحتوي على حرف واحد صغير، وعلى الأقل تحتوي على رقم واحد، وعلى الأقل تحتوي على حرف خاص واحد",
  "Confirm password & password does not match!":
    "تأكيد كلمة المرور وكلمة المرور غير متطابقة!",
  "Old Password": "كلمة المرور القديمة",
  Ok: "حسنا",
  "IOS Details": "IOS Details",
  Version: "النسخة",
  "Update App Setting": "Update App Setting",
  "Please enter the profile image!": "اختار صورة الملف الشخصي",
  "Phone number must be between 8 and 12 digits":
    "يجب أن يتراوح رقم الهاتف بين 8 و12 رقمًا",
  "Please enter phone number": "الرجاء إدخال رقم الهاتف",
  Name: "الاسم",
  "File format is not correct": "نوع الملف غير صحيح",
  "Image must be smaller than 5 MB!": "يجب أن يكون حجم الصورة أصغر من 5 ميغابايت!",
  Notifications: "الاشعارات",
  "Collector Details": "تفاصيل المحصل",
  Name: "الاسم",
  "Phone Number": "رقم الهاتف",
  "Email Address": "البريد الالكتروني",
  "City's": "المدينة",
  "AED 0": "AED 0",
  Year: "السنة",
  "Rest. ID": "الرقم",
  NAME: "الاسم",
  "payment Date & time": "تاريخ ووقت الدفعة",
  Country: "الدولة",
  City: "المدينة",
  Area: "المنطقة",
  "Total Amount": "المبلغ الاجمالي",
  "Payment Mode": "طريقة الدفع",
  "Payment receipt": "وصل الدفع",
  status: "الحالة",
  "Restaurant NAME": "اسم المطعم",
  "Invoice Date": "تاريخ الفاتورة",
  Country: "الدولة",
  City: "المدينة",
  Area: "المنطقة",
  "Total Amount": "المبلغ الاجمالي",
  "Paid Amount": "المبلغ المدفوع",
  "Remaining  Amount": "المبلغ المتبقي",
  Action: "الاجراء",
  City: "المدينة",
  Restaurant: "المطعم",
  "total Payable Amount": "المبلغ الاجمالي المستحق",
  "Restaurant Name": "اسم المطعم",
  City: "المدينة",
  Area: "المنطقة",
  Date: "التاريخ",
  "Date is required": "التاريخ مطلوب",
  "Select Date": "اختار التاريخ",
  Time: "الوقت",
  "Time is required": "الوقت مطلوب",
  "Total Amount": "المبلغ الاجمالي",
  "Amount is required": "المبلغ مطلوب",
  "Amount is not greater then invoice remaining amount":
    "المبلغ ليس أكبر من المبلغ المتبقي في الفاتورة",
  "Upload Image": "تحميل الصورة",
  "Choose a Payment": "اختيار الدفعة",
  "Payment Type is required": "نوع الدفع مطلوب",
  "Full payment": "دفعة كاملة",
  "Partial payment": "دفعة جزئية",
  NAME: "الاسم",
  "payment Date & time": "تاريخ ووقت الدفعة",
  "Payment Mode": "طريقة الدفع",
  "Payment receipt": "وصل الدفع",
  status: "الحالة",
  "Approval Pending": "بانتظار الموافقة",
  "Payment Mode": "طريقة الدفع",
  Status: "الحالة",
  Pending: "مستحق",
  Approved: "تمت الموافقة",
  Rejected: "تم الرفض",
  Restaurant: "المطعم",
  "Receive Payment": "استلام الدفعة",
  Reason: "السبب",
  "Abu Dhabi": "Abu Dhabi",
  Downtown: "Downtown",
  "Total Amount": "المبلغ الاجمالي",
  "Login to your Account Collector": "الدخول الى حساب المحصل",
  "See what is going on with your business":
    "تعرف على ما يحدث في عملك",
  "Email Address": "البريد الالكتروني",
  "Please enter a valid email address!": "يرجى إدخال عنوان بريد إلكتروني صالح!",
  "Email address not more then 255 characters!":
    "عنوان البريد الإلكتروني لا يزيد عن 255 حرفًا!",
  "Please enter your email!": "رجاءا أدخل بريدك الإلكتروني!",
  "Enter Email Address": "أدخل بريدك الإلكتروني!",
  Password: "كلمة المرور",
  "Password should contain more then 255 characters!":
    "يجب أن تحتوي كلمة المرور على أكثر من 255 حرفًا!",
  "Please enter your password!": "من فضلك أدخل كلمة المرور",
  "Enter Password": "ادخل كلمة المرور",
  "Remember me": "تذكرني",
  "Forgot Password?": "نسيت كلمة المرور؟",
  Login: "الدخول",
  "Send OTP": "Send OTP",
  "Forgot Password": "نسيت كلمة المرور",
  "Email Address": "البريد الالكتروني",
  "Please enter a valid email address!": "يرجى إدخال عنوان بريد إلكتروني صالح!",
  "Email address not more then 255 characters!":
    "عنوان البريد الإلكتروني لا يزيد عن 255 حرفًا!",
  "Please enter your email!": "رجاءا أدخل بريدك الإلكتروني!",
  "Enter Email Address": "أدخل بريدك الإلكتروني!",
  "Verify OTP": "Verify OTP",
  Verify: "التاكيد",
  OTP: "OTP",
  "Please enter the OTP": "Please enter the OTP",
  "OTP must be 4 digits": "OTP must be 4 digits",
  "Reset Password": "اعادة تعيين كلمة المرور",
  "Reset": "اعادة الضبط",
  "Update Password": "تحديث كلمة المرور",
  "New Password": "كلمة المرور الجديدة",
  "Please enter your new password!": "الرجاء إدخال كلمة المرور الجديدة!",
  "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "كلمة المرور الجديدة تحتوي على الأقل على 8 أحرف، وعلى الأقل تحتوي على حرف كبير واحد، وعلى الأقل تحتوي على حرف واحد صغير، وعلى الأقل تحتوي على رقم واحد، وعلى الأقل تحتوي على حرف خاص واحد",
  "Enter New Password": "أدخل كلمة المرور الجديدة",
  "Receive Payment":'يستلم الراتب',
  "Cancel": "الغاء",
  "Ok": "نعم",

};

export default ar;