import { Image, Menu, Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import Cath from "../../assets/images/side_nav/cath.svg";
import Chart from "../../assets/images/side_nav/chart.svg";
import Coust from "../../assets/images/side_nav/customer.svg";
import Dash from "../../assets/images/side_nav/dashbord.svg";
import Log from "../../assets/images/side_nav/log.svg";
import Blog from "../../assets/images/side_nav/blog.svg";
import Rest from "../../assets/images/side_nav/resturant.svg";
import Bio from "../../assets/images/side_nav/bi_collection.svg";
import { AuthContext } from "../../context/AuthContext";
import DeleteModal from "../DeleteModal";
import lang from "../../helper/langHelper";
import moment, { isMoment } from "moment";

export const menuItems = [
  {
    key: "Dashboard",
    path: "/dashboard",
    icon: Dash,
    label: "Dashboard",
  },
  {
    key: "CollectionList",
    label: "Collection List",
    icon: Chart,
    path: "/collections",
  },
  // {
  //   key: "PendingApprovals",
  //   label: "Pending Approvals",
  //   icon: Bio,
  //   path: "/pending-approvals",
  // },

  // {
  //   key: "PaymentHistory",
  //   label: "Payment History",
  //   icon: Blog, //
  //   path: "/payment-history",
  // },
];

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { userProfile, logout, setUserProfile } = useContext(AuthContext);
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuLinks, setMenuLinks] = useState([]);
  const [menuMode, setMenuMode] = useState("vertical");
  const [currentDateTime, setCurrentDateTime] = useState(moment());

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const showDeleteConfirm = (record) => {
    logout();
  };

  const renderTitle = (item) => {
    return (
      <>
        <Image src={item.icon} preview={false} />
        <span className="label">{lang(item.label)}</span>
      </>
    );
  };

  useEffect(() => {
    setMenuLinks(menuItems);
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMenuMode("inline");
      } else {
        setMenuMode("vertical");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      setCurrentDateTime(moment());
    };

    const intervalId = setInterval(updateDateTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {/* {loading ? (
        [1, 2, 3, 4, 5, 6].map((item) => <Skeleton active key={item} />)
      ) : ( */}
      <div className="brand-logo">
        <NavLink to="" className="imgOuter">
          <img className="" src={Logo} alt="" />
        </NavLink>
        <p style={{ color: "white" }}>
          {currentDateTime.format("YYYY-MM-DD hh:mm a")}
        </p>
      </div>
      <Menu inlineCollapsed={false} mode={menuMode} className="sideNavMain">
        {menuLinks.map((item) => {
          if (item.children) {
            return (
              <>
                <Menu.SubMenu
                  key={item.key}
                  title={
                    <>
                      <span className="icon">
                        <Image src={item.icon} preview={false} />
                      </span>
                      <span className="label">{lang(item.label)}</span>
                    </>
                  }
                >
                  {item.children.map((child) => (
                    <Menu.Item key={child.key}>
                      <NavLink to={child.path}>{lang(child.label)}</NavLink>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              </>
            );
          }

          return (
            <Menu.Item key={item.key}>
              <NavLink to={item.path}>{renderTitle(item)}</NavLink>
            </Menu.Item>
          );
        })}

        <Menu.Item onClick={showDeleteConfirm}>
          <NavLink to={""}>
            <>
              <Image preview={false} src={Log} />
              <span className="label">{lang("Logout")}</span>
            </>
          </NavLink>
        </Menu.Item>
      </Menu>
      {isLogoutModalVisible && (
        <DeleteModal
          title={lang("Logout")}
          subtitle={lang(`Are you sure you want to Logout the Application?`)}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => logout()}
        />
      )}
    </>
  );
}

export default Sidenav;
