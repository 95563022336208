const en = {
  Dashboard: "Dashboard",
  "Collection List": "Collection List",
  "Pending Approvals": "Pending Approvals",
  "Payment History": "Payment History",
  Logout: "Logout",
  "Are you sure you want to Logout the Application?":
    "Are you sure you want to Logout the Application?",
  "Edit Profile": "Edit Profile",
  "Change Password": "Change Password",
  "Please enter the name!": "Please enter the name!",
  "Only Alphabetic Characters Allowed": "Only Alphabetic Characters Allowed",
  "The input is not valid E-mail!": "The input is not valid E-mail!",
  "Enter Email Address": "Enter Email Address",
  "Please enter the email!": "Please enter the email!",
  "Mobile Number": "Mobile Number",
  "Upload Profile": "Upload Profile",
  "App Store URL": "App Store URL",
  "Android Details": "Android Details",
  "Enter App Store URL": "Enter App Store URL",
  "Collection Management": "Collection Management",
  "This Month Collection": "This Month Collection",
  "Full Payment": "Full Payment",
  Payments: "Payments",
  Month: "Month",
  "Approval Pending": "Approval Pending",
  "Collection Management": "Collection Management",
  "Pending Approval": "Pending Approval",
  "VIEW ALL": "VIEW ALL",
  Approved: "Approved",
  "Payment History": "Payment History",
  "Collection List": "Collection List",
  "Receive Payment": "Receive Payment",
  Invoice: "Invoice",
  "Are you sure you want to Logout the Application?":
    "Are you sure you want to Logout the Application?",
  Logout: "Logout",
  "Please enter the name!": "Please enter the name!",
  "Only Alphabetic Characters Allowed": "Only Alphabetic Characters Allowed",
  "Enter Name": "Enter Name",
  Upload: "Upload",
  "Please enter the app store URL!": "Please enter the app store URL!",
  "Please enter the version!": "Please enter the version!",
  "Enter Android Version": "Enter Android Version",
  "Please enter the share content!": "Please enter the share content!",
  "Share Content": "Share Content",
  "Share Android Content": "Share Android Content",
  "Play Store URL": "Play Store URL",
  "Please enter the play store URL!": "Please enter the play store URL!",
  "Enter Play Store URL": "Enter Play Store URL",
  Version: "Version",
  "Please enter the version!": "Please enter the version!",
  "Enter IOS Version": "Enter IOS Version",
  "Share Content": "Share Content",
  "Please enter the share content!": "Please enter the share content!",
  "Share IOS Content": "Share IOS Content",
  "Change Password": "Change Password",
  "Please enter the old password!": "Please enter the old password!",
  "New Password": "New Password",
  "Please enter the new password!": "Please enter the new password!",
  "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
  "Old password & new password must be different!":
    "Old password & new password must be different!",
  "Confirm New Password": "Confirm New Password",
  "Please enter the confirm password!": "Please enter the confirm password!",
  "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
  "Confirm password & password does not match!":
    "Confirm password & password does not match!",
  "Old Password": "Old Password",
  Ok: "Ok",
  "IOS Details": "IOS Details",
  Version: "Version",
  "Update App Setting": "Update App Setting",
  "Please enter the profile image!": "Please enter the profile image!",
  "Phone number must be between 8 and 12 digits":
    "Phone number must be between 8 and 12 digits",
  "Please enter phone number": "Please enter phone number",
  Name: "Name",
  "File format is not correct": "File format is not correct",
  "Image must be smaller than 5 MB!": "Image must be smaller than 5 MB!",
  Notifications: "Notifications",
  "Collector Details": "Collector Details",
  Name: "Name",
  "Phone Number": "Phone Number",
  "Email Address": "Email Address",
  "City's": "City's",
  "AED 0": "AED 0",
  Year: "Year",
  "Rest. ID": "Rest. ID",
  NAME: "NAME",
  "payment Date & time": "payment Date & time",
  Country: "Country",
  City: "City",
  Area: "Area",
  "Total Amount": "Total Amount",
  "Payment Mode": "Payment Mode",
  "Payment receipt": "Payment receipt",
  status: "status",
  "Restaurant NAME": "Restaurant NAME",
  "Invoice Date": "Invoice Date",
  Country: "Country",
  City: "City",
  Area: "Area",
  "Total Amount": "Total Amount",
  "Paid Amount": "Paid Amount",
  "Remaining  Amount": "Remaining  Amount",
  Action: "Action",
  City: "City",
  Restaurant: "Restaurant",
  "total Payable Amount": "total Payable Amount",
  "Restaurant Name": "Restaurant Name",
  City: "City",
  Area: "Area",
  Date: "Date",
  "Date is required": "Date is required",
  "Select Date": "Select Date",
  Time: "Time",
  "Time is required": "Time is required",
  "Total Amount": "Total Amount",
  "Amount is required": "Amount is required",
  "Amount is not greater then invoice remaining amount":
    "Amount is not greater then invoice remaining amount",
  "Upload Image": "Upload Image",
  "Choose a Payment": "Choose a Payment",
  "Payment Type is required": "Payment Type is required",
  "Full payment": "Full payment",
  "Partial payment": "Partial payment",
  NAME: "NAME",
  "payment Date & time": "payment Date & time",
  "Payment Mode": "Payment Mode",
  "Payment receipt": "Payment receipt",
  status: "status",
  "Approval Pending": "Approval Pending",
  "Payment Mode": "Payment Mode",
  Status: "Status",
  Pending: "Pending",
  Approved: "Approved",
  Rejected: "Rejected",
  Restaurant: "Restaurant",
  "Receive Payment": "Receive Payment",
  Reason: "Reason",
  "Abu Dhabi": "Abu Dhabi",
  Downtown: "Downtown",
  "Total Amount": "Total Amount",
  "Login to your Account Collector": "Login to your Account Collector",
  "See what is going on with your business":
    "See what is going on with your business",
  "Email Address": "Email Address",
  "Please enter a valid email address!": "Please enter a valid email address!",
  "Email address not more then 255 characters!":
    "Email address not more then 255 characters!",
  "Please enter your email!": "Please enter your email!",
  "Enter Email Address": "Enter Email Address",
  Password: "Password",
  "Password should contain more then 255 characters!":
    "Password should contain more then 255 characters!",
  "Please enter your password!": "Please enter your password!",
  "Enter Password": "Enter Password",
  "Remember me": "Remember me",
  "Forgot Password?": "Forgot Password?",
  Login: "Login",
  "Send OTP": "Send OTP",
  "Forgot Password": "Forgot Password",
  "Email Address": "Email Address",
  "Please enter a valid email address!": "Please enter a valid email address!",
  "Email address not more then 255 characters!":
    "Email address not more then 255 characters!",
  "Please enter your email!": "Please enter your email!",
  "Enter Email Address": "Enter Email Address",
  "Verify OTP": "Verify OTP",
  Verify: "Verify",
  OTP: "OTP",
  "Please enter the OTP": "Please enter the OTP",
  "OTP must be 4 digits": "OTP must be 4 digits",
  "Reset Password": "Reset Password",
  "Update Password": "Update Password",
  "New Password": "New Password",
  "Please enter your new password!": "Please enter your new password!",
  "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character":
    "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
  "Enter New Password": "Enter New Password",
};

export default en;
