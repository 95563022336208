import {
  DownOutlined,
  KeyOutlined,
  LogoutOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import { useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { uploadFile } from "react-s3";
import Logo from "../../assets/images/Logo.png";
import notfound from "../../assets/images/not_found.png";
import Notification from "../../assets/images/notification.svg";
import DeleteModal from "../../components/DeleteModal";
import { s3Config } from "../../config/s3Config";
import apiPath from "../../constants/apiPath";
import { AuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useAppContext } from "../../context/AppContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { uploadToS3 } from "../../helper/s3";
import lang from "../../helper/langHelper";
import { useNavigate } from "react-router";
import { getFileExtension } from "../../helper/functions";

const { confirm } = Modal;
const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

const currency = ["AED", "INR"];

const languages = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Arabic",
    value: "ar",
  },
];

function Header({ name: sectionHeading, onPress, setToggle }) {
  useEffect(() => window.scrollTo(0, 0));
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const [profile, setProfile] = useState({});
  const [selected, setSelected] = useState();
  const [profileVisible, setProfileVisible] = useState(false);

  const [appSetting, setAppSetting] = useState({});
  const [appSettingSelected, setAppSettingSelected] = useState();
  const [appSettingVisible, setAppSettingVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const { request } = useRequest();
  const [refresh, setRefresh] = useState(false);
  const { logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { setCountry, language } = useAppContext();
  const { isAdmin } = useContext(AuthContext);
  const [notification, setNotification] = useState([]);
  const { pathname } = useLocation();

  const notificationitems = [
    {
      label: (
        <div className="notification_top">
          <div className="notification-head">
            <h5>Notifications</h5>
          </div>
          <div className="notification-inner">
            {notification.map((item) => (
              <div key={item._id} className="single-notification">
                {/* <div className="notification-img">
                  <img src={Notify1} />
                </div> */}
                <div className="notification-cont">
                  {/* <p>
                  <span>Vendor Heeba Khan</span> attach a deposit receipt to the
                  App bank account of <span>AED 100.00</span>
                </p> */}

                  <p>
                    <span>
                      {item.users.length ? item.users[0].message : ""}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="viewAll_notification">
            <Button
              onClick={() => navigate("/notifications")}
              className="btnStyle btn_primary"
            >
              {lang("View All")}
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
          data.length &&
            setCountry((prev) => ({ ...prev, country_id: data[0]._id }));
        }
      },
    });
  };

  const fetchData = () => {
    request({
      url: `${apiPath.notification}/top-five`,
      method: "GET",
      onSuccess: (data) => {
        setNotification(data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    console.log("Notification Load");
    fetchData();
  }, [pathname]);

  const items = [
    {
      label: lang("Edit Profile"),
      key: "1",
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: lang("Change Password"),
      key: "2",
      icon: <KeyOutlined />,
      danger: true,
    },
    {
      label: lang("Logout"),
      key: "3",
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];

  const showDeleteConfirm = (record) => {
    logout();
  };

  const handleMenuClick = (e) => {
    console.log(e, "handleMenuClick");
    if (e.key == 2) {
      setVisible(true);
    }
    if (e.key == 1) {
      setProfileVisible(true);
    }
    if (e.key == 4) {
      setAppSettingVisible(true);
    }
    if (e.key == 3) {
      showDeleteConfirm();
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleCreate = (values) => {
    onCreate(values);
  };

  const onCreate = (values) => {
    const { old_password, new_password } = values;
    const payload = {};
    if (!old_password || !new_password)
      return ShowToast("Please enter password ", Severty.ERROR);
    setLoading(true);
    payload.new_password = new_password;
    payload.old_password = old_password;
    request({
      url: apiPath.changePassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setVisible(false);
          logout();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    request({
      url: apiPath.profile,
      method: "GET",
      onSuccess: (data) => {
        console.log(data, "App Profile");
        setProfile(data.data);
        setSelected(data.data);
      },
    });
    request({
      url: apiPath.getAppSetting,
      method: "GET",
      onSuccess: (data) => {
        console.log(data, "App Setting");
        setAppSetting(data.data);
        setAppSettingSelected(data.data);
      },
    });
  }, [refresh]);

  return (
    <>
      <Row gutter={[24, 0]} className="justify-content-between mx-0">
        <Col xs={24} md={24} sm={24} lg={12} className="SectionMain px-0">
          <div className="toggale-headr">
            <div className="">
              <Button
                type="link"
                className="sidebar-toggler ps-0 d-none d-lg-block"
                onClick={() => setToggle()}
              >
                {toggler}
              </Button>
            </div>
            <div className="d-none d-lg-block">{sectionHeading}</div>
          </div>
          <div className="tabLogo d-block d-lg-none">
            <img className="w-100" src={Logo} />
          </div>
        </Col>

        <Col span={24} xs={24} sm={24} lg={12} className="header-control px-0">
          <Button
            type="link"
            className="sidebar-toggler ps-0"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          <div className="language-wrap">
            <Select
              value={language}
              options={languages}
              onChange={(value) => {
                localStorage.setItem("languageSet", value);
                window.location.reload();
              }}
            />
          </div>

          <div className="notification-header d-lg-block">
            <Dropdown
              menu={{ items: notificationitems }}
              trigger={["click"]}
              className="notification-box"
            >
              <Button>
                <img src={Notification} />
                <span className="active_notification"></span>
              </Button>
            </Dropdown>
          </div>

          <div className="profileDropdownMain">
            <Dropdown menu={menuProps} trigger={["click"]}>
              <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open">
                <div className=" d-flex align-items-center gap-2">
                  <div className="userImg">
                    <Image
                      src={profile ? profile.image : notfound}
                      preview={false}
                    />
                  </div>
                  <div className="d-none d-xl-block">
                    <div className="userName">
                      {profile ? profile?.name : "Administrator"}
                      <DownOutlined />
                    </div>
                  </div>
                </div>
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>

      {isLogoutModalVisible && (
        <DeleteModal
          title={lang("Logout")}
          subtitle={lang(`Are you sure you want to Logout the Application?`)}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => logout()}
        />
      )}

      {profileVisible && (
        <EditProfile
          show={profileVisible}
          hide={() => {
            setProfileVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {appSettingVisible && (
        <AppSetting
          show={appSettingVisible}
          hide={() => {
            setAppSettingVisible(false);
          }}
          data={appSettingSelected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {visible && (
        <ChangePassword
          show={visible}
          handleCreate={handleCreate}
          hide={() => {
            setVisible(false);
          }}
        />
      )}
    </>
  );
}

const EditProfile = ({ show, hide, data, refresh }) => {
  console.log(data, "Profile Edit");
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);

  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const beforeUpload = (file) => {
    if (FileType.includes(file.type)) {
    } else {
      message.error(lang("File format is not correct"));
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error(lang(`Image must be smaller than 5 MB!`));
      return false;
    }
    return true;
  };

  const handleChange = async (event) => {
    const { file } = event;

    const extension = getFileExtension(file.name);
    const name = `TAWASI_${new Date().getTime()}.${extension}`;

    const newFile = new File([file], name, { type: file.type });
    //setFile([newFile]);

    uploadToS3({ file: newFile, type: "profile" })
      .then((data) => {
        console.log("File Upload", newFile, data);
        const fileData = {
          uid: file.uid,
          name: name,
          status: "done",
          url: data.location,
          thumbUrl: data.location,
        };
        setFile([fileData]);
        setImage(data.location);
      })
      .catch((err) => console.error(err));

    /* uploadFile(newFile, s3Config("profile"))
      .then((data) => {
        const fileData = {
          uid: newFile.uid,
          name: newFile.name,
          status: "done",
          url: data.location,
          thumbUrl: data.location,
        };
        setFile([fileData]);
        console.log(data);
      })
      .catch((err) => console.error(err)); */
  };

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({
      ...data,
      mobile: data.country_code ? data.country_code + data.mobile_number : "",
    });
    if (data.image != undefined) {
      setImage(data.image);
    } else {
      setImage(notfound);
    }
  }, [data]);

  const onEditProfile = (values) => {
    const { email, name } = values;
    console.log(file.length <= 0 || !image);
    if (file.length <= 0 && !image)
      return ShowToast("Please select the profile Image ", Severty.ERROR);
    const payload = { ...mobileNumber };
    setLoading(true);
    payload.email = email;
    payload.name = name;
    payload.image = file.length > 0 ? file[0].url : null;
    request({
      url: apiPath.updateProfile,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleChangePhone = (value, data) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data.dialCode.length),
    });
  };

  return (
    <Modal
      open={show}
      okText="Ok"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <h4 className="modal_title_cls">{lang("Edit Profile")}</h4>
      <Form id="create" form={form} onFinish={onEditProfile} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              label={lang("Name")}
              name="name"
              rules={[
                { required: true, message: lang("Please enter the name!") },
                {
                  pattern: new RegExp(/^[a-zA-Z ]*$/),
                  message: lang("Only Alphabetic Characters Allowed"),
                },
              ]}
            >
              <Input placeholder={lang("Enter Name")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={lang("Email Address")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: lang("The input is not valid E-mail!"),
                },
                { required: true, message: lang("Please enter the email!") },
              ]}
            >
              <Input placeholder={lang("Enter Email Address")} disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={lang("Mobile Number")}
              name="mobile"
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject(lang("Please enter phone number"));
                    }
                    if (!/^\d{8,15}$/.test(value)) {
                      return Promise.reject(
                        lang("Phone number must be between 8 and 12 digits")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: false,
                  autoFormat: false,
                  autoComplete: "off",
                }}
                isValid={(value, country) => {
                  const countryCode = country.iso2;
                  const number = value.slice(country?.dialCode?.length);
                  if (countryCode === "il") {
                    if (number && !/^5/.test(number))
                      return lang("Number must start with number 5.");
                    if (number && !/^972\d{9}$/.test(value))
                      return lang("Number must be 9 digits.");
                  }
                  if (countryCode === "ps") {
                    if (number && !/^5/.test(number))
                      return lang("Number must start with number 5.");
                    if (number && !/^970\d{9}$/.test(value))
                      return lang("Number must be 9 digits");
                  }

                  if (!/^\d+$/.test(value)) {
                    return "Invalid value: " + value;
                  }
                  return true;
                }}
                country={"il"}
                preferredCountries={["ps", "il"]}
                onChange={handleChangePhone}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className="upload_wrap"
              label={lang("Upload Profile")}
              name="image"
              rules={[
                {
                  required: file.length > 0 ? false : true,
                  message: lang("Please enter the profile image!"),
                },
              ]}
            >
              <Upload
                listType="picture"
                maxCount={1}
                beforeUpload={beforeUpload}
                customRequest={handleChange}
                onRemove={(e) => setFile([])}
                fileList={file}
              >
                {file && file.length > 0 ? null : (
                  <Button icon={<UploadOutlined />}>{lang("Upload")}</Button>
                )}
              </Upload>

              {image && (
                <div className="mt-3">
                  <Image width={300} src={image}></Image>
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const AppSetting = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({ ...data });
  }, [data]);

  const onAppSetting = (values) => {
    setLoading(true);
    request({
      url: apiPath.updateAppSetting,
      method: "POST",
      data: values,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      width={1200}
      open={show}
      // title={`${data ? "Update App Setting" : ""}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <h4 className="modal_title_cls">{lang("Update App Setting")}</h4>
      <Form id="create" form={form} onFinish={onAppSetting} layout="vertical">
        <Row gutter={{ xs: [0, 16], md: [16, 0] }}>
          <Col span={24} md={12}>
            <Card title={lang("Android Details")}>
              <Col span={24}>
                <Form.Item
                  label={lang("App Store URL")}
                  name="app_store_url"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the app store URL!"),
                    },
                  ]}
                >
                  <Input placeholder={lang("Enter App Store URL")} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={lang("Version")}
                  name="android_version"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the version!"),
                    },
                  ]}
                >
                  <Input placeholder={lang("Enter Android Version")} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={lang("Share Content")}
                  name="android_share_content"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the share content!"),
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder={lang("Share Android Content")}
                  />
                </Form.Item>
              </Col>
            </Card>
          </Col>

          <Col span={24} md={12}>
            <Card title={lang("IOS Details")}>
              <Col span={24} className="">
                <Form.Item
                  label={lang("Play Store URL")}
                  name="play_store_url"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the play store URL!"),
                    },
                  ]}
                >
                  <Input placeholder={lang("Enter Play Store URL")} />
                </Form.Item>
              </Col>

              <Col span={24} className="">
                <Form.Item
                  label={lang("Version")}
                  name="ios_version"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the version!"),
                    },
                  ]}
                >
                  <Input placeholder={lang("Enter IOS Version")} />
                </Form.Item>
              </Col>

              <Col span={24} className="">
                <Form.Item
                  label={lang("Share Content")}
                  name="ios_share_content"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the share content!"),
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder={lang("Share IOS Content")}
                  />
                </Form.Item>
              </Col>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const ChangePassword = ({ show, hide, handleCreate }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={show}
      // title="Change password"
      okText={lang("Ok")}
      onCancel={hide}
      //onOk={handleCreate}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        //loading: loading,
      }}
    >
      <h4 className="modal_title_cls">{lang("Change Password")}</h4>
      <Form id="create" form={form} onFinish={handleCreate} layout="vertical">
        <Form.Item
          label={lang("Old Password")}
          name="old_password"
          hasFeedback
          rules={[
            { required: true, message: lang("Please enter the old password!") },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={lang("New Password")}
          name="new_password"
          dependencies={["old_password"]}
          hasFeedback
          rules={[
            { required: true, message: lang("Please enter the new password!") },
            {
              pattern: new RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
              ),
              message: lang(
                "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character"
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("old_password") === value) {
                  return Promise.reject(
                    new Error(
                      lang("Old password & new password must be different!")
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={lang("Confirm New Password")}
          name="confirm_new_password"
          dependencies={["new_password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: lang("Please enter the confirm password!"),
            },
            {
              pattern: new RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
              ),
              message: lang(
                "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character"
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(lang("Confirm password & password does not match!"))
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Header;
