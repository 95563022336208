import {
  Avatar,
  Card,
  Col,
  Row,
  Select,
  Skeleton,
  Tabs,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import notfound from "../../assets/images/not_found.png";
import Money from "../../assets/images/revenue.png";
import { Last20Years, Months } from "../../constants/var";
import { AppStateContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";
import BarChart from "./_BarChart";
import HistoryFive from "./_History";
import LineChart from "./_LineCart";
import PendingFive from "./_Pending";
import lang from "../../helper/langHelper";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

function Home() {
  const { setPageHeading } = useContext(AppStateContext);
  const { userProfile } = useAuthContext();

  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState();

  const [dashboard, setDashboard] = useState();

  useEffect(() => {
    setPageHeading(lang("Collection Management"));
    // setLoading(true);
    // request({
    //   url: apiPath.dashboard,
    //   method: "GET",
    //   onSuccess: (data) => {
    //     setLoading(false);
    //     setDashboard(data.data);
    //   },
    //   onError: (error) => {
    //     setLoading(false);
    //     ShowToast(error, Severty.ERROR);
    //   },
    // });
  }, [year]);

  return (
    <>
      <div className="page-top-space home-card layout-content">
        <div className="tabled categoryService">
          <Row gutter={[24, 0]}>
            <Col xs={24} xl={24} className="mb-24 ">
              <Card
                bordered={false}
                className="cap criclebox tablespace card-first-collector "
              >
                <div className="title-left-coll">
                  <h4>{lang("Collector Details")}</h4>
                </div>
                <Row gutter={24}>
                  <Col xs={24} sm={16} md={16} xl={16} className="sm-padding-0">
                    <div className="topbar-user-img">
                      <Avatar
                        className="user-border-inner"
                        size="large"
                        src={userProfile?.image ?? notfound}
                      />
                      <div className="detail-social-text">
                        <div className="Collector-details-first">
                          <p> {lang("Name")} :</p>
                          <span className="Collector-details">
                            {userProfile?.name}
                          </span>
                        </div>
                        {userProfile?.country_code && (
                          <div className="Collector-details-first">
                            <p>{lang("Phone Number")} :</p>
                            <span className="Collector-details">
                              ({userProfile?.country_code})
                              {userProfile?.mobile_number}
                            </span>
                          </div>
                        )}
                        <div className="Collector-details-first">
                          <p> {lang("Email Address")} :</p>
                          <span className="Collector-details">
                            {userProfile?.email}
                          </span>
                        </div>
                        {userProfile?.city_ids && (
                          <div className="Collector-details-first">
                            <p>{lang("City's")} : </p>
                            <span className="Collector-details">
                              {userProfile?.city_ids
                                .map((item) => item.name)
                                .join(", ")}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col xs={24} sm={8} md={8} xl={8} className="sm-padding-0">
                    <Card className="collection_card">
                      <div className="cls-total received-amt collection-inner">
                        <div className="sales-img-icon">
                          <img src={Money}></img>
                        </div>
                        <div className="sales-text-outer ">
                          <h6>{lang(`This Month Collection`)}</h6>
                          <h4>{lang("AED 0")}</h4>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <div></div>
              </Card>
            </Col>
          </Row>
        </div>

        <Row className=" " gutter={[24, 0]}>
          <FullPayment />
          <Payment />
        </Row>
        <PendingFive />
        <HistoryFive />
      </div>
    </>
  );
}

const FullPayment = () => {
  const { currency } = useAuthContext();

  const { request } = useRequest();
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState(0);

  const [dashboard, setDashboard] = useState();
  const [labels, setLabels] = useState([]);
  const [list, setList] = useState();

  const [filter, setFilter] = useState({
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${apiPath.collection}/filters/Approved`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years, restaurants } = res;
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const fetchData = () => {
    setLoading(true);
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url: `${apiPath.collection}/full-payment${
        queryString ? `?${queryString}` : ""
      }`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          setList(data);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    getFilter();
  }, [filter]);

  useEffect(() => {
    const label = Array.from(
      { length: numberOfDay(filter.month ?? new Date().getMonth() + 1) },
      (_, index) => index + 1,
    );
    setLabels(label);
  }, [filter.month]);

  useEffect(() => {
    if (!list) return;
    let daysArrayCurrent = labels.map((i) => null);
    let daysArrayPrev = labels.map((i) => null);

    if (list.current.length) {
      list.current.forEach((item) => {
        let dateParts = item._id.split("-");
        let day = parseInt(dateParts[2], 10);
        console.log(day, "DAy");
        daysArrayCurrent[day - 1] = item.amount;
      });
    }

    if (list.prev.length) {
      list.prev.forEach((item) => {
        let dateParts = item._id.split("-");
        let day = parseInt(dateParts[2], 10);

        daysArrayPrev[day - 1] = item.amount;
      });
    }

    const data = {
      labels,
      datasets: [
        {
          label: "Provisions Month",
          data: daysArrayPrev,
          borderColor: "#F3E008",
          tension: 0.1,
          spanGaps: true,
        },
        {
          label: `Selected Month`,
          data: daysArrayCurrent,
          borderColor: "#383B42",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          spanGaps: true,
        },
      ],
    };

    setDashboard(data);
  }, [list]);

  return (
    <Col xs={24} xl={16} className="mb-24">
      <Card bordered={false} className="criclebox h-full">
        <div className="graph-title">
          <Title level={5}>{lang(`Full Payment`)}</Title>
          <Row>
            <div className="d-flex align-items-center gap-3">
              <div className="custom_select">
                <Select
                  placeholder={lang("Year")}
                  className="select-width-fix"
                  value={filter.year}
                  options={years.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => onChange("year", value)}
                />
              </div>
              <div className="custom_select">
                <Select
                  placeholder={lang("Month")}
                  className="select-width-fix"
                  showSearch
                  value={filter.month}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={months.map(({ value, label }) => ({
                    value,
                    label,
                  }))}
                  onChange={(value) => onChange("month", value)}
                />
              </div>
            </div>
          </Row>
        </div>
        {loading ? (
          [1, 2, 3].map((item) => <Skeleton active key={item} />)
        ) : (
          <>
            <LineChart borderColor="#1EB564" data={dashboard} />
          </>
        )}
      </Card>
    </Col>
  );
};

const Payment = () => {
  const { currency } = useAuthContext();

  const { request } = useRequest();
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState(0);

  const [dashboard, setDashboard] = useState();
  const [list, setList] = useState();

  const [filter, setFilter] = useState({
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${apiPath.collection}/filters/Approved`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years, restaurants } = res;
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const fetchData = () => {
    setLoading(true);
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url: `${apiPath.collection}/payments${
        queryString ? `?${queryString}` : ""
      }`,
      method: "GET",
      onSuccess: ({ data, total, status }) => {
        setLoading(false);
        if (status) {
          setList(data);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    getFilter();
  }, [filter]);

  useEffect(() => {
    if (!list) return;

    const labels = ["1st Week", "2nd Week", "3rd Week", "4th Week"];

    let full_payments = Array.from({ length: 4 }).map((i) => null);
    let partial_payments = Array.from({ length: 4 }).map((i) => null);

    list.map(({ full_amount, partial_amount, interval }) => {
      full_payments[interval - 1] = full_amount;
      partial_payments[interval - 1] = partial_amount;
    });

    const dummy = {
      labels,
      datasets: [
        {
          label: `${lang("Full Payment")} : ${currency} ${full_payments.reduce(
            (sum, curr) => sum + Number(curr),
            0,
          )}`,
          data: full_payments,
          backgroundColor: "#4990F2",
        },
        {
          label: `${lang("Partial payment")} :  ${currency} ${partial_payments.reduce(
            (sum, curr) => sum + Number(curr),
            0,
          )}`,
          data: partial_payments,
          backgroundColor: "#F3E008",
        },
      ],
    };

    setDashboard(dummy);
    setPayment(
      [...full_payments, ...partial_payments].reduce(
        (sum, curr) => sum + Number(curr),
        0,
      ),
    );
  }, [list]);

  return (
    <Col xs={24} xl={8} className="mb-24">
      <Card bordered={false} className="criclebox h-full">
        <div className="graph-title">
          <Title level={5}>
            {lang(`Payments`)}
            <h4>{` ${currency} ${payment}`}</h4>
          </Title>

          <Row>
            <div className="d-flex align-items-center gap-3">
              <div className="custom_select">
                <Select
                  placeholder={lang("Year")}
                  className="select-width-fix"
                  value={filter.year}
                  options={years.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => onChange("year", value)}
                />
              </div>
              <div className="custom_select">
                <Select
                  placeholder={lang("Month")}
                  className="select-width-fix"
                  showSearch
                  value={filter.month}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={months.map(({ value, label }) => ({
                    value,
                    label,
                  }))}
                  onChange={(value) => onChange("month", value)}
                />
              </div>
            </div>
          </Row>
        </div>
        {loading ? (
          [1, 2, 3].map((item) => <Skeleton active key={item} />)
        ) : (
          <>
            <BarChart borderColor="#0E9CFF" data={dashboard} />
          </>
        )}
      </Card>
    </Col>
  );
};

const numberOfDay = (month) => {
  let currentDate = new Date();
  let currentMonth = month ?? 1;
  let daysInMonth = new Date(
    currentDate.getFullYear(),
    currentMonth,
    0,
  ).getDate();
  return daysInMonth;
};
export default Home;
